/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Link from 'gatsby-link'
import { graphql } from 'gatsby'
import Pagination from '../components/pagination'
import VerticalMenu from '../components/blogComponents/verticalMenu'
import BricoBrowser from '../assets/logo.svg'

const Blog = ({data}) => {
    const {edges: posts} = data.allMarkdownRemark
    const totalItems = data.allMarkdownRemark.totalCount
    const [ currentPage, setCurrentPage ] = useState(1)
    const itemsPerPage = 2
    const indexOfLastItem = currentPage *itemsPerPage
    const indexOfFirstItem = indexOfLastItem - itemsPerPage
    const paginate = (pageNumber) => setCurrentPage(pageNumber)

    return (
        <Layout>
            <Seo title="Blog" description="Blog page Ricta Browser" />
            <div className="back">
                <div className="flex justify-end">
                    <BricoBrowser style={{width:'600px',height:'160px',marginTop:'80px',backgroundAttachment: 'fixed',backgroundSize: 'cover'}}/>
                </div>
            </div>
            <section className="comment">
                <div className="leftPart">
                    {posts.slice(indexOfFirstItem, indexOfLastItem).map(({node: post,key}) => {
                        const {frontmatter} = post
                        return (
                            <>
                                <div key={key} className="firstComment">
                                    <h3><Link to ={frontmatter.path}>{frontmatter.title}</Link></h3>
                                    <p className="little">{frontmatter.date}</p>
                                    <p><i>{frontmatter.excerpt} </i></p>
                                    <button><Link to={frontmatter.path}>Read more</Link></button>
                                </div>
                                <hr/>
                            </>
                        )
                    })}
                    <Pagination itemsPerPage={itemsPerPage} totalItems={totalItems} paginate={paginate} currentPage={currentPage} />
                   
                </div>
                <div className="vertical"> 
                </div>
                <VerticalMenu/>
            </section>
        </Layout>

    )

}
export const query = graphql`
query BlogQuery {
    allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            path
            date(formatString: "MMMM DD, YYYY")
            tags
            excerpt
          }
        }
      }
    }
  }
`
export default Blog