/* eslint-disable react/prop-types */
import React from 'react'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

const Pagination = ({itemsPerPage, totalItems, paginate, currentPage}) => {
    const pageNumbers =  Array.from({length: Math.ceil(totalItems/itemsPerPage)}, (_, index) => index + 1)
    return (
        <div className="flex justify-left">
            <div className="pagination">
                <div className="previous" onClick={()=>paginate(currentPage-1)}>
                    <ArrowBackIosIcon style={{width:'23px',height:'23px'}} className="text-textColor mb-1"/>
                    <a>Previous</a>
                </div>
                <div className="flex justify-center">
                    {pageNumbers.map(pageNumber => 
                        <button key={pageNumber} type="button" onClick={()=>paginate(pageNumber)} className={`${parseInt(currentPage)===pageNumber ? 'bg-mainColor text-white': 'bg-white text-textColor'} hidden md:flex w-10 h-10 mx-1 justify-center items-center rounded-full border outline-none border-gray-200 hover:border-gray-300`}>
                            {pageNumber}
                        </button>

                    )}
                </div>
                <div className="nextt" onClick={()=>paginate(currentPage+1)}>
                    <a>Next</a>
                    <ArrowForwardIosIcon style={{width:'23px',height:'23px'}} className="text-textColor mb-1"/>
                </div>
            </div>
        </div>
    )

}
export default Pagination